exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-accessibility-js": () => import("./../../../src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-feedback-and-complaints-js": () => import("./../../../src/pages/feedback-and-complaints.js" /* webpackChunkName: "component---src-pages-feedback-and-complaints-js" */),
  "component---src-pages-home-designs-and-funding-js": () => import("./../../../src/pages/home-designs-and-funding.js" /* webpackChunkName: "component---src-pages-home-designs-and-funding-js" */),
  "component---src-pages-how-we-work-with-you-js": () => import("./../../../src/pages/how-we-work-with-you.js" /* webpackChunkName: "component---src-pages-how-we-work-with-you-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-our-services-js": () => import("./../../../src/pages/our-services.js" /* webpackChunkName: "component---src-pages-our-services-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */)
}

